<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_barcode_info_add" />
      <!-- バーコード情報登録画面 -->
      <v-container fluid>
        <v-row class="search-row">
          <v-form ref="defaultForm" class="d-flex">
            <!-- 営業所CD -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                dense
                readonly
                v-model="defaultForm.officeSelected"
                :label="$t('label.lbl_office_cd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 取引先CD -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="defaultForm.clientSelected"
                :items="defaultForm.clientList"
                :label="$t('label.lbl_client_cd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="clientHint()"
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-form>
        </v-row>
        <v-row class="justify-space-between mb-0 mt-0">
          <!-- 戻るボタン -->
          <v-col cols="3" class="text-left">
            <div>
              <v-btn
                color="primary"
                id="btn-content-confirm"
                class="other-btn ml-0"
                @click="backBtnDialog()"
                >{{ $t("btn.btn_back") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <!-- タイプ選択 -->
        <v-row class="mt-0 pt-0" justify="center">
          <v-col :cols="2" class="justify-center pt-0">
            <v-form ref="typeSelectForm">
              <v-container>
                <v-row class="justify-center">
                  <v-col
                    class="text-center pa-2 mb-2"
                    style="border: 1px solid #bdbdbd; background-color: #effad9; font-weight: bold"
                  >
                    {{ $t("label.lbl_type_select") }}
                  </v-col>
                </v-row>
                <v-row class="justify-center mb-2">
                  <v-btn color="primary" id="btn-content-confirm" class="" @click="decisionBtn()">{{
                    $t("btn.btn_decision")
                  }}</v-btn>
                </v-row>
                <div
                  class="cards-container"
                  @mouseenter="showScrollbar"
                  @mouseleave="hideScrollbar"
                  style="height: calc(100vh - 279px)"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-card
                        dense
                        outlined
                        hover
                        class="my-1"
                        v-for="item in typeSelectForm.bcrTypeList"
                        v-bind:key="item.bcrType"
                        :class="{ selected: item.bcrType === typeSelectForm.selectedType }"
                        @click="bcrTypeClick(item.bcrType)"
                      >
                        <v-card-title>{{ item.bcrType + "：" + item.codeName }}</v-card-title>
                        <v-card-text v-if="item.remarks">
                          {{ item.remarks }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-form>
          </v-col>
          <!-- 項目設定 -->
          <v-col :cols="5" class="justify-center pt-0">
            <v-form ref="itemSettingsForm">
              <v-container>
                <v-row class="justify-center mb-2">
                  <v-col
                    class="text-center pa-2 mb-2"
                    style="
                      border: 1px solid #bdbdbd;
                      background-color: rgb(221, 235, 247);
                      font-weight: bold;
                    "
                  >
                    {{ $t("label.lbl_item_settings") }}
                  </v-col>
                </v-row>
                <v-row class="d-flex search-row">
                  <!-- 選択タイプ -->
                  <div class="pt-1 flex-grow-0">
                    <v-text-field
                      dense
                      readonly
                      v-model="itemSettings.selectedTypeName"
                      :label="$t('label.lbl_selected_type')"
                      class="txt-single"
                      persistent-hint
                      :rules="[rules.inputRequired]"
                    ></v-text-field>
                  </div>
                  <span class="require asterisk-spacer flex-grow-0">*</span>
                  <!-- ラベル名 -->
                  <div class="ml-2 flex-grow-1">
                    <v-text-field
                      dense
                      outlined
                      class="search-autocomplete"
                      :label="$t('label.lbl_label_name')"
                      v-model="itemSettings.labelName"
                      maxlength="50"
                      clear-icon="mdi-close-circle"
                      clearable
                      :rules="[rules.inputRequired]"
                      style="width: 100%"
                    />
                  </div>
                  <span class="require asterisk-spacer flex-grow-0">*</span>
                  <v-btn
                    color="primary"
                    id="btn-content-confirm"
                    class="ml-2 mr-2 flex-grow-0"
                    @click="clearBtnDialog"
                    >{{ $t("btn.btn_searchDataClear") }}</v-btn
                  >
                  <v-btn
                    color="primary"
                    id="btn-content-confirm"
                    class="flex-grow-0"
                    @click="addBtnDialog"
                    >{{ $t("btn.btn_insert") }}</v-btn
                  >
                </v-row>
                <v-row>
                  <v-container fluid class="pa-0">
                    <v-data-table
                      id="listData"
                      fixed-header
                      :headers="itemSettings.itemSettingsListHeaders"
                      :items="itemSettings.itemSettingsList"
                      disable-filtering
                      disable-sort
                      disable-pagination
                      :hide-default-footer="true"
                      height="calc(100vh - 309px)"
                    >
                      <!-- ヘッダー：桁数(可変) -->
                      <template v-slot:[`header.fixedLength`]="{}">
                        {{ $t("label.lbl_fixed_length") + "(" + $t("label.lbl_variable") }}
                        <input
                          type="checkbox"
                          v-model="itemSettings.variableFlg"
                          style="width: 15px; height: 15px"
                        />
                        {{ ")" }}
                      </template>
                      <!-- 項目 -->
                      <template v-slot:[`item.items`]="{ item, index }">
                        <v-combobox
                          dense
                          v-model="item.items"
                          :items="filteredBcrItems"
                          item-text="codeName"
                          return-object
                          maxlength="20"
                          clear-icon="mdi-close-circle"
                          :rules="isCheckItem(item, index) ? [rules.inputRequired] : []"
                        >
                        </v-combobox>
                      </template>
                      <!-- 桁数 -->
                      <template v-slot:[`item.fixedLength`]="{ item, index }">
                        <v-text-field
                          dense
                          outlined
                          v-model="item.fixedLength"
                          class="input-number"
                          persistent-hint
                          :rules="
                            isCheckItem(item, index)
                              ? [
                                  rules.inputRequired,
                                  rules.isNumber,
                                  rules.isInteger,
                                  rules.isRangeCheckBara,
                                  rules.limitNumLength,
                                ]
                              : []
                          "
                          clear-icon="mdi-close-circle"
                        />
                      </template>
                      <!-- 方式 -->
                      <template v-slot:[`item.repeatFlg`]="{ item, index }">
                        <v-autocomplete
                          dense
                          v-model="item.repeatFlg"
                          :items="itemSettings.methodList"
                          item-value="repeatFlg"
                          persistent-hint
                          :rules="isCheckItem(item, index) ? [rules.inputRequired] : []"
                          :readonly="item.isReadOnlyRepeatFlg"
                        >
                          <template slot="item" slot-scope="data">
                            <span class="test">
                              {{ data.item.text }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
          <!-- 登録済ラベル -->
          <v-col :cols="5" class="justify-center pt-0">
            <v-container>
              <v-row class="justify-center mb-2">
                <v-col
                  class="text-center pa-2 mb-2"
                  style="
                    border: 1px solid #bdbdbd;
                    background-color: rgb(217, 217, 217);
                    font-weight: bold;
                  "
                >
                  {{ $t("label.lbl_registered_label") }}
                </v-col>
              </v-row>
              <v-row class="d-flex search-row">
                <div class="pa-0 align-self-end">{{ $t("label.lbl_registration_status") }}</div>
                <v-text-field
                  dense
                  readonly
                  class="search-autocomplete mr-auto pt-1"
                  persistent-hint
                  style="visibility: hidden; width: 0"
                ></v-text-field>
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="btn-content-confirm"
                  class="mr-2"
                  @click="searchBtn(false)"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!-- 修正ボタン -->
                <v-btn color="primary" id="btn-content-confirm" class="mr-2" @click="editBtn">{{
                  $t("btn.btn_fix")
                }}</v-btn>
                <!-- 削除ボタン -->
                <v-btn color="primary" id="btn-content-confirm" @click="delBtnDialog">{{
                  $t("btn.btn_delete")
                }}</v-btn>
              </v-row>
              <v-row>
                <v-container fluid class="pa-0 pt-3">
                  <v-data-table
                    id="listData"
                    fixed-header
                    :headers="registeredLabelListHeaders"
                    :items="registeredLabelList"
                    disable-filtering
                    disable-sort
                    disable-pagination
                    :hide-default-footer="true"
                    height="calc(100vh - 309px)"
                  >
                    <template v-slot:[`item.check`]="{ item }">
                      <input type="checkbox" v-model="item.check" style="transform: scale(2)" />
                    </template>
                    <!-- タイプ -->
                    <template v-slot:[`item.bcrTypeName`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <div class="text-start" v-on="on">
                            <template v-if="item.bcrTypeName.length > 7">
                              {{ item.bcrTypeName.substring(0, 6) + "..." }}
                            </template>
                            <template v-else>
                              {{ item.bcrTypeName }}
                            </template>
                          </div>
                        </template>
                        <span>{{ item.bcrTypeName }}</span>
                      </v-tooltip>
                    </template>
                    <!-- 総桁数 -->
                    <template v-slot:[`item.labelDigits`]="{ item }">
                      <div class="text-end">{{ item.labelDigits }}</div>
                    </template>
                  </v-data-table>
                </v-container>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :title="ConfirmDialog.title"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_INI_005,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    openMenu: false,
    // 基本情報
    defaultForm: {
      // 営業所
      officeSelected: null,
      officeList: [],
      // 取引先
      clientSelected: null,
      clientList: [],
    },
    // タイプ選択
    typeSelectForm: {
      // 選択
      selectedType: null,
      // バーコード種類
      bcrTypeList: [],
    },
    // 項目設定
    itemSettings: {
      // 選択
      selectedType: null,
      selectedTypeName: "",
      // ラベル
      labelName: "",
      // バーコード項目
      bcrItemList: [],
      // 可変フラグ
      variableFlg: false,
      deleteList: [],
      // 方式
      methodList: [],
      itemSettingsList: [],
      itemSettingsListHeaders: [
        // 順番
        {
          text: i18n.tc("label.lbl_order"),
          value: "no",
          width: "2%",
          align: "center",
        },
        // 項目
        {
          text: i18n.tc("label.lbl_items"),
          value: "items",
          width: "12%",
          align: "center",
        },
        // 桁数
        {
          text: i18n.tc("label.lbl_fixed_length"),
          value: "fixedLength",
          width: "4%",
          align: "center",
        },
        // 方式
        {
          text: i18n.tc("label.lbl_method"),
          value: "repeatFlg",
          width: "4%",
          align: "center",
        },
      ],
    },
    // 登録済ラベル
    registeredLabelList: [],
    registeredLabelListHeaders: [
      // 選択
      {
        text: i18n.tc("label.lbl_select"),
        value: "check",
        width: "70px",
        align: "center",
      },
      // タイプ
      {
        text: i18n.tc("label.lbl_type"),
        value: "bcrTypeName",
        width: "150px",
        align: "center",
      },
      // 総桁数
      {
        text: i18n.tc("label.lbl_total_number_of_digits"),
        value: "labelDigits",
        width: "90px",
        align: "center",
      },
      // ラベル名
      {
        text: i18n.tc("label.lbl_label_name"),
        value: "listName",
        align: "left",
      },
    ],
  }),
  methods: {
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    showScrollbar(event) {
      event.target.style.overflowY = "auto";
    },
    hideScrollbar(event) {
      event.target.style.overflowY = "hidden";
    },
    // 初期化
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 項目設定初期化
      this.itemSettingsInit();
      // 営業所
      const officeList = getParameter.getOffice();
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // バーコード種類
      const bcrTypeList = getParameter.getBcrType({ physicalDb1: "m901" });
      // バーコード項目
      const bcrItemList = getParameter.getBcrItem({ physicalDb1: "m901" });
      // 方式
      const codeList = getParameter.getCodeMst(appConfig.CODETYPE.METHOD_DIV);
      Promise.all([officeList, clientList, bcrTypeList, bcrItemList, codeList])
        .then((result) => {
          // 画面の初期値を設定します。
          // 営業所
          this.defaultForm.officeList = result[0];
          this.defaultForm.officeSelected = this.defaultForm.officeList[0].text;
          // 取引先
          this.defaultForm.clientList = result[1];
          // バーコード種類
          this.typeSelectForm.bcrTypeList = result[2];
          // バーコード項目
          this.itemSettings.bcrItemList = result[3];
          // 方式
          result[4].forEach((data) => {
            this.itemSettings.methodList.push({ repeatFlg: data.value, text: data.text });
          });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 取引先
    clientHint() {
      const client = this.defaultForm.clientList.find(
        (data) => data.value == this.defaultForm.clientSelected
      );
      return client ? client.name : "";
    },
    // タイプ選択
    bcrTypeClick(bcrType) {
      this.typeSelectForm.selectedType = bcrType;
    },
    // 項目設定初期化
    itemSettingsInit() {
      this.itemSettings.deleteList = [];
      // バリデーションチェックをリセット
      this.$refs.itemSettingsForm.resetValidation();
      for (let i = 1; i <= 20; i++) {
        this.$set(this.itemSettings.itemSettingsList, i - 1, {
          no: i,
          items: "",
          fixedLength: "",
          repeatFlg: "0",
          isReadOnlyRepeatFlg: false,
          deleteFlg: "0",
        });
      }
      // タイプ選択情報
      this.itemSettings.selectedType = null;
      this.itemSettings.selectedTypeName = "";
      // ラベル名
      this.itemSettings.labelName = "";
      // 桁数(可変)
      this.itemSettings.variableFlg = false;
    },
    // 項目チェック判定
    isCheckItem(checkItem, idx) {
      return (
        this.itemSettings.itemSettingsList.reduceRight(
          (acc, { item, fixedLength }, index) =>
            !!item || (!!fixedLength && acc === -1) ? index : acc,
          -1
        ) >= idx ||
        !!checkItem.items ||
        !!checkItem.fixedLength
      );
    },
    /**
     * 戻るダイアログ
     */
    backBtnDialog() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_004_W");
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.backBtn;
    },
    /**
     * 戻る
     */
    backBtn() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_INI_004,
      });
    },
    /**
     * 決定ボタンダイアログ
     */
    selectedTypeDialog() {},
    /**
     * 決定ボタン
     */
    decisionBtn() {
      const item = this.typeSelectForm.bcrTypeList.find(
        (data) => data.bcrType === this.typeSelectForm.selectedType
      );
      if (item) {
        // 項目設定初期化
        this.itemSettingsInit();
        // タイプ選択情報
        this.itemSettings.selectedType = item;
        this.itemSettings.selectedTypeName = item.bcrType + "：" + item.codeName;
        // タイプが30番台の場合、方式はボタン固定
        if (30 <= item.bcrType && item.bcrType < 40) {
          this.itemSettings.itemSettingsList.forEach((data) => {
            data.repeatFlg = "1";
            data.isReadOnlyRepeatFlg = true;
          });
        }
      } else {
        this.infoDialog.message = i18n.tc("check.chk_select");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * クリアダイアログ
     */
    clearBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_searchDataClear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearBtn;
    },
    /**
     * クリア
     */
    clearBtn() {
      // 項目設定初期化
      this.itemSettingsInit();
    },
    /**
     * 登録ダイアログ
     */
    addBtnDialog() {
      if (this.$refs.defaultForm.validate() && this.$refs.itemSettingsForm.validate()) {
        const dataCnt = this.itemSettings.itemSettingsList.filter((data) => !!data.items).length;
        if (dataCnt > 0) {
          this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
          this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
            i18n.tc("label.lbl_regist"),
          ]);
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.ConfirmDialog.okAction = this.addBtn;
        } else {
          this.infoDialog.message = messsageUtil.getMessage("P-DST-002_001_E", [
            i18n.tc("label.lbl_item_settings"),
          ]);
          this.infoDialog.title = i18n.tc("btn.btn_ok");
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        }
      }
    },
    /**
     * 登録
     */
    addBtn() {
      const qrList = [...this.itemSettings.deleteList];
      qrList.forEach((data) => {
        // 削除フラグ
        data.deleteFlg = "1";
      });
      const addList = [];
      this.itemSettings.itemSettingsList.forEach((data) => {
        if (data.items && data.fixedLength && data.repeatFlg) {
          let remarks = "";
          if (data.items !== null && typeof data.items === "object") {
            remarks = data.items.item01;
          } else if (typeof data.items === "string") {
            remarks = data.items;
          }
          let repeatFlg = data.repeatFlg;
          if (data.repeatFlg == "1") {
            const findItem = this.itemSettings.bcrItemList.find(
              (bcrItem) => bcrItem.item01 == remarks
            );
            if (findItem) {
              repeatFlg = findItem.item03;
            }
          }
          addList.push({
            officeSid: sessionStorage.getItem("sales_office_sid"),
            clientSid: this.defaultForm.clientSelected,
            fromSid: this.defaultForm.clientSelected,
            bcrType: this.itemSettings.selectedType.bcrType,
            procCd: data.procCd ? data.procCd : 100,
            autoNo: data.no,
            labelDigits: data.labelDigits,
            listName: this.itemSettings.labelName,
            startPosition: data.startPosition,
            endPosition: data.endPosition,
            fixedLength: data.fixedLength,
            repeatFlg: repeatFlg,
            remarks: remarks,
            deleteFlg: data.deleteFlg,
          });
        }
      });
      let startPos = 1;
      let endPos = 0;
      for (let i = 0; i < addList.length; i++) {
        const diff = parseInt(addList[i].fixedLength, 10);
        endPos = startPos + diff - 1;
        addList[i].startPosition = startPos;
        addList[i].endPosition = endPos;
        startPos = endPos + 1;
      }
      addList.forEach((addItem) => {
        if (this.itemSettings.variableFlg) {
          // 可変の場合、ラベル桁数を0にセット
          addItem.labelDigits = 0;
        } else {
          addItem.labelDigits = endPos;
        }
      });
      qrList.push(...addList);
      this.loadingCounter = 1;
      const addResult = this.apiQrAddRemove({ qrDefinitionList: qrList }, false);
      Promise.all([addResult])
        .then(() => {
          this.searchBtn(true);
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * 検索
     */
    searchBtn(refreshFlg) {
      // 営業所、取引先のバリデーション
      if (refreshFlg || this.$refs.defaultForm.validate()) {
        let conditions = {};
        let continueFlg = true;
        if (refreshFlg) {
          if (Object.keys(this.searchFilter).length != 0) {
            conditions = this.searchFilter;
          } else {
            // 検索痕跡が存在しない場合、処理しない
            continueFlg = false;
          }
        } else {
          conditions = { clientSid: this.defaultForm.clientSelected };
        }
        if (continueFlg) {
          this.loadingCounter = 1;
          const qrList = this.apiQrSearch(conditions);
          Promise.all([qrList])
            .then((result) => {
              const list = [];
              result[0].forEach((row) => {
                const bcrTypeName = this.typeSelectForm.bcrTypeList.find(
                  (item) => item.bcrType === row.bcrType
                );
                list.push({
                  check: false,
                  officeSid: row.officeSid,
                  clientSid: row.clientSid,
                  bcrType: row.bcrType,
                  labelDigits: row.labelDigits,
                  bcrTypeName: bcrTypeName ? bcrTypeName.bcrType + "：" + bcrTypeName.codeName : "",
                  listName: row.qrDefinitionList[0].listName,
                  qrDefinitionList: row.qrDefinitionList,
                });
              });
              this.registeredLabelList = list;
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              this.loadingCounter = 0;
            });
        }
      }
    },
    /**
     * 修正
     */
    editBtn() {
      const checkCnt = this.registeredLabelList.filter((data) => data.check === true).length;
      if (checkCnt === 1) {
        // 項目設定初期化
        this.itemSettingsInit();
        const checkList = this.registeredLabelList.filter((data) => data.check === true);
        const itemList = [].concat(...checkList.map((data) => data.qrDefinitionList));
        Object.assign(this.itemSettings.deleteList, itemList);
        const itemFormatList = itemList.filter((data) => data.procCd === 100);
        // 選択タイプ
        const item = this.typeSelectForm.bcrTypeList.find(
          (data) => data.bcrType === itemFormatList[0].bcrType
        );
        this.itemSettings.selectedType = item;
        this.itemSettings.selectedTypeName = item ? item.bcrType + ":" + item.codeName : "";
        // ラベル名
        this.itemSettings.labelName = itemFormatList[0].listName;
        // 桁数(可変)
        this.itemSettings.variableFlg = itemFormatList[0].labelDigits == 0 ? true : false;
        // タイプが30番台の場合、方式は修正できない
        if (30 <= item.bcrType && item.bcrType < 40) {
          this.itemSettings.itemSettingsList.forEach((data) => {
            data.repeatFlg = "1";
            data.isReadOnlyRepeatFlg = true;
          });
        }
        // 項目設定
        itemFormatList.forEach((data) => {
          this.itemSettings.itemSettingsList.forEach((item) => {
            if (data.autoNo == item.no) {
              for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                  let val = data[key];
                  if (key === "repeatFlg") {
                    if (["1", "2", "3", "4", "5"].includes(val)) {
                      val = "1";
                    } else {
                      val = "0";
                    }
                  }
                  this.$set(item, key, val);
                }
              }
              item.items = this.itemSettings.bcrItemList.find(
                (data) => data.item01 == item.remarks
              );
              let readOnlyFlg = false;
              if (!item.items) {
                item.items = item.remarks;
                readOnlyFlg = true;
              } else {
                if (item.items.item03 == null) {
                  item.repeatFlg = "0";
                  readOnlyFlg = true;
                }
              }
              if (readOnlyFlg) {
                item.repeatFlg = "0";
                item.isReadOnlyRepeatFlg = true;
              }
            }
          });
        });
        // タイプ選択を外す
        this.typeSelectForm.selectedType = null;
      } else if (checkCnt > 1) {
        this.infoDialog.message = messsageUtil.getMessage("P-TMP-903_003_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * 削除ダイアログ
     */
    delBtnDialog() {
      const checkCnt = this.registeredLabelList.filter((data) => data.check === true).length;
      if (checkCnt > 0) {
        this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
        this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("label.lbl_delete"),
        ]);
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.ConfirmDialog.okAction = this.delBtn;
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * 削除
     */
    delBtn() {
      const delList = this.registeredLabelList.filter((data) => data.check === true);
      const reqDelList = [].concat(...delList.map((data) => data.qrDefinitionList));
      reqDelList.forEach((data) => {
        // 削除フラグ
        data.deleteFlg = "1";
      });
      this.loadingCounter = 1;
      const delResult = this.apiQrAddRemove({ qrDefinitionList: reqDelList }, true);
      Promise.all([delResult])
        .then(() => {
          this.searchBtn(true);
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * API：QR位置情報の取得
     */
    apiQrSearch(conditions) {
      const config = this.$httpClient.createGetApiRequestConfig();
      Object.assign(config.params, conditions);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.INI_QR_DEFINITIONS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 検索条件
              this.searchFilter = conditions;
              resolve(jsonData.resIdv.qrDefinitionList);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          });
      });
    },
    /**
     * API：QR位置情報の登録・削除
     */
    apiQrAddRemove(conditions, deleteFlg, messageIgnoreFlg = false) {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_005; // 画面ID
      Object.assign(body.reqIdv, conditions);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.INI_QR_DEFINITIONS, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (!messageIgnoreFlg) {
                if (!deleteFlg) {
                  // 登録
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                } else {
                  // 削除
                  this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
                }
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          });
      });
    },
  },
  computed: {
    filteredBcrItems() {
      // タイプが30番台の場合、ボタン値が存在する項目だけ表示
      if (
        this.itemSettings.selectedType &&
        30 <= this.itemSettings.selectedType.bcrType &&
        this.itemSettings.selectedType.bcrType < 40
      ) {
        return this.itemSettings.bcrItemList.filter((item) => {
          return item.item03 != null;
        });
      }
      return this.itemSettings.bcrItemList;
    },
  },
  watch: {
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    "itemSettings.itemSettingsList": {
      deep: true,
      handler() {
        this.itemSettings.itemSettingsList.forEach((item, index) => {
          this.$watch(
            () => item.items,
            (newVal) => {
              let readOnlyFlg = false;
              if (newVal !== null && typeof newVal === "object") {
                const bcrItem = this.itemSettings.bcrItemList.find(
                  (bcrItem) => bcrItem.item01 == newVal.item01
                );
                if (bcrItem.item03 == null) {
                  readOnlyFlg = true;
                }
              } else if (typeof newVal === "string") {
                readOnlyFlg = true;
              }

              let readOnly30sFlg = false;
              // タイプが30番台の場合、方式はボタン固定
              if (
                this.itemSettings.selectedType &&
                30 <= this.itemSettings.selectedType.bcrType &&
                this.itemSettings.selectedType.bcrType < 40
              ) {
                readOnly30sFlg = true;
              }

              if (readOnly30sFlg || readOnlyFlg) {
                if (!readOnly30sFlg) {
                  this.itemSettings.itemSettingsList[index].repeatFlg = "0";
                }
                this.itemSettings.itemSettingsList[index].isReadOnlyRepeatFlg = true;
              } else {
                this.itemSettings.itemSettingsList[index].isReadOnlyRepeatFlg = false;
              }
            }
          );
        });
      },
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.cards-container {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.selected {
  border-top: groove 3px #00bfff; /* 上 */
  border-bottom: groove 3px #00bfff; /* 下 */
  border-left: groove 3px #00bfff; /* 左 */
  border-right: groove 3px #00bfff; /* 右 */
}
</style>
